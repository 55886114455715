import Parse from "parse"
import React, {useState, useEffect} from 'react';
import { useHistory  } from "react-router-dom"
import { IsStoreOpen } from "../services/store"
import { getStatusOnDeliveryDistance, getDeliveryPrice } from "../services/delivery"
import { getDistance, checkDeviceType } from "../services/helper"
import { clearBasket } from "../services/basket"
import { Logout } from "../services/user"
import { Container  } from '@material-ui/core';
import { Stores } from "../components/stores"
import { Navbar, NavbarSpace } from "../components/navbar"
import {HomeProducts} from "../components/home_products"
import { FooterZio } from "../components/footer"
import { NewBreadcrumbs } from "../components/breadcrumbs"

// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//     dataLayer: {
//       event: 'pageview',
//       pagePath: '/home',
//       pageTitle: 'Home',
//     },
// }

function getStoreLogo(stores, store_object_id)
{
  for (let i = 0; i < stores.length; i++) 
  {
    if (stores[i].id === store_object_id)
    {
      return stores[i].logo_image_url
    }
  }
}

async function getLists(open_store_ids, setLists, stores, section_category)
{
    const current_time = Date.now();
    const List = Parse.Object.extend("List");
    const query = new Parse.Query(List);
    query.equalTo("isActive", true)
    query.greaterThan("expirationDate", current_time/1000 )
    query.lessThan("startDate", current_time/1000)
    query.ascending("sortIndex")
    query.limit(20)
    query.containedIn("targetType",["product", "wall"])
    query.containedIn("availabilityTag", ["all", section_category])

    const results = await query.find()
    let _lists = []
    let _tags = []
    if (results)
    { 
      for (let i = 0; i < results.length; i++) 
      {
          _lists.push({
            name: results[i].get("name"),
            tag: results[i].get("tag"),
            misc: results[i].get("misc"),
            items: []
          })
          if (_tags.indexOf(results[i].get("tag")))
          _tags.push(results[i].get("tag"))
      }
    }

    // map items to lists 
    const Product = Parse.Object.extend("Product");
    const queryProduct = new Parse.Query(Product);
    queryProduct.equalTo("isActive", true)
    queryProduct.equalTo("status", 0)
    queryProduct.notEqualTo("stock", 0)
    queryProduct.containedIn("promotionTag", _tags)
    queryProduct.ascending("priority")
    queryProduct.limit(120)
    queryProduct.containedIn("storeObjectId", open_store_ids)
    const resultsProduct = await queryProduct.find().catch((e) => { });

    for (let i = 0; i < _lists.length; i++) 
    {
      if (_lists[i].tag === "free_delivery" || _lists[i].tag === "daily_promo" || _lists[i].tag === "multi_item_discount" || _lists[i].tag === "daily_promo")
      {

      }
      else
      {
        for (let j = 0; j < resultsProduct.length; j++) 
        {
          let _add_item = false
          const _promotion_tag = resultsProduct[j].get("promotionTag")
          if (_lists[i].misc && _lists[i].misc.tags)
          {
            for (let k = 0; k < _lists[i].misc.tags.length; k++) 
            {
              if (_lists[i].misc.tags[k] === _promotion_tag || _lists[i].tag === resultsProduct[j].get("tag") )
              {
                _add_item = true
              }
            }
          }
          else  
          {
            if (_lists[i].tag === _promotion_tag )
            {
              _add_item = true
            }
          }
          if (_add_item)
          {
            const _store_object_id = resultsProduct[j].get("storeObjectId")
            // update store info to show if the promo is available
            if (_promotion_tag != null && _promotion_tag != "new_product")
            {
              for (let k = 0; k < stores.length; k++)
              {
                if (stores[k].id === _store_object_id)
                {
                                
                  if (_promotion_tag === "daily_promo")
                  {
                    stores[k].promotion_label = "Promocja dnia"
                  }
                  else if (_promotion_tag === "multi_item_discount")
                  {
                    stores[k].promotion_label = resultsProduct[j].get("name")
                  }
                  else if (_promotion_tag === "dish_of_the_day")
                  {
                    stores[k].promotion_label = "danie dnia"
                  }
                  else if (_promotion_tag === "two_for_one")
                  {
                    stores[k].promotion_label = "promocja 2 za 1"
                  }
                  else if (_promotion_tag === "zero_waste")
                  {
                    stores[k].promotion_label = "dostępna paczka KUPUJĘ RATUJĘ"
                  }
                  
                  if (_promotion_tag === "free_delivery")
                  {
                    stores[k].delivery_price = 0
                    stores[k].promotion_label = "darmowa dostawa"
                  }  
                }
              }
            }
            let _diet = {}
            const product_diet = resultsProduct[j].get("diets")
            if (product_diet)
            {
                for (let i = 0; i < product_diet.length; i++)
                {
                    if (product_diet[i] == "vegan")
                    {
                        _diet.vegan = true
                    }
                    else if (product_diet[i] == "vegetarian")
                    {
                        _diet.vegetarian = true
                    }
                    else if (product_diet[i] == "glutenfree")
                    {
                        _diet.glutenfree = true
                    }
                    else if (product_diet[i] == "soyafree")
                    {
                        _diet.soyafree = true
                    }
                }
            }
            if (_lists[i].items.length < 12)
            {

              _lists[i].items.push({
                name: resultsProduct[j].get("name"),
                price: resultsProduct[j].get("price"),
                original_price: resultsProduct[j].get("originalPrice"),
                id: resultsProduct[j].id,
                store_object_id: _store_object_id,
                image_url: resultsProduct[j].get("imageSmallUrlAdmin")?resultsProduct[j].get("imageSmallUrlAdmin").url():getStoreLogo(stores, resultsProduct[j].get("storeObjectId")),
                diets: _diet
              })
            }
          }
        }
      }
    }

    setLists(_lists)
}

async function fetchData(setStoresOpenAndDeliver, setClosedStores, setStoresOpenAndTooFar, lat, lon, history, setLists, section_category) {

    if (lat == null && lon == null)
    {
      console.log("no address")
    }
    else
    { 
      
      const location = new Parse.GeoPoint(parseFloat(lat), parseFloat(lon));
      const distance = 40;

      const Store = Parse.Object.extend("Store");
      const query = new Parse.Query(Store);
      query.withinKilometers("location", location, distance, true);
      query.equalTo("isActive", true)
      query.equalTo("platform", "web") // DEBUG
      query.equalTo("availabilityTag", section_category)
      query.limit(500)

      if (section_category === "catering")
      {
        query.ascending("sortIndex")
      }

      const results = await query.find().catch((e) => {
        if (e.code === 209)
        {
          Logout()
          history.push('/start')
        }
      });

      if (results && results.length)
      {
        let stores_opened_and_delivery = [];
        let stores_opened_and_delivery_ids = []
        let stores_opened_and_too_far = []
        let stores_closed = []

        for (let i = 0; i < results.length; i++) {
          const store_location = results[i].get("location")
          let _location = {}
          if (results[i].get("deliveryInformation").location)
          {
             _location = results[i].get("deliveryInformation").location
          } 
          else
          {
            _location = store_location
          }
            
          results[i].delivery_distance = getDistance({ latitude: lat, longitude: lon },{ latitude: _location.latitude, longitude: _location.longitude  }  )
          results[i].store_distance = getDistance({ latitude: lat, longitude: lon },{ latitude: store_location.latitude, longitude: store_location.longitude  }  )
          const features = results[i].get("features")
          let feature_zero_waste = false
          if (features)
          {
            if (features.zero_waste)
            {
              feature_zero_waste = true
            }
          }
          if ( IsStoreOpen(results[i].get("isOpen"), results[i].get("openingHours") ) )
          {
            if (getStatusOnDeliveryDistance(results[i].get("deliveryInformation"), results[i].get("deliveryType"), results[i].delivery_distance) === "ok")
            {
             
              stores_opened_and_delivery.push({
                  id:results[i].id,
                  name:results[i].get("name"),
                  image_url:results[i].get("imageUrlAdmin").url(),
                  image_small_url:results[i].get("imageSmallUrlAdmin").url(),
                  logo_image_url:results[i].get("logoUrlAdmin").url(),
                  is_open:true,
                  distance: results[i].store_distance,
                  delivery_price: getDeliveryPrice(results[i].get("deliveryInformation"), results[i].store_distance).price,
                  zero_waste: feature_zero_waste
              })
              stores_opened_and_delivery_ids.push(results[i].id)
            }
            else 
            {
              stores_opened_and_too_far.push({
                  id:results[i].id,
                  name:results[i].get("name"),
                  image_url:results[i].get("imageUrlAdmin").url(),
                  image_small_url:results[i].get("imageSmallUrlAdmin").url(),
                  logo_image_url:results[i].get("logoUrlAdmin").url(),
                  is_open:true,
                  distance: results[i].store_distance,
                  delivery_price: getDeliveryPrice(results[i].get("deliveryInformation"), results[i].store_distance).price,
                  features: feature_zero_waste
              })
            }
          }
          else
          {
            stores_closed.push({
                id: results[i].id,
                name: results[i].get("name"),
                image_url: results[i].get("imageUrlAdmin").url(),
                image_small_url:results[i].get("imageSmallUrlAdmin").url(),
                logo_image_url: results[i].get("logoUrlAdmin").url(),
                is_open: false,
                distance: results[i].store_distance,
                delivery_price: getDeliveryPrice(results[i].get("deliveryInformation"), results[i].store_distance).price,
                features: feature_zero_waste
            })
          }
        }

        await getLists(stores_opened_and_delivery_ids, setLists, stores_opened_and_delivery, section_category)
        setStoresOpenAndDeliver(stores_opened_and_delivery);
        setStoresOpenAndTooFar(stores_opened_and_too_far);      
        setClosedStores(stores_closed);
      }
    }

}

export default function Home({ match }) {
  const [storesOpenAndDeliver, setStoresOpenAndDeliver] = useState([])
  const [storesOpenAndTooFar, setStoresOpenAndTooFar] = useState([])
  const [storesClosed, setClosedStores] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchPhrase, setSearchPhrase] = useState([])
  const [lists, setLists] = useState([])
  const [section, setSection] = useState("")
  const history = useHistory();


  const lat = localStorage.getItem("primary_address_latitude")
  const lon = localStorage.getItem("primary_address_longitude")
  // const router = useRouter();


  useEffect(() => {
    let section_category = "restaurant"
    if (match.path.search("catering") != -1)
    {
      section_category = "catering"
    }
    else if (match.path.search("torty") != -1)
    {
      section_category = "cake"
    }
    else if (match.path.search("restauracje") != -1)
    {
      section_category = "restaurant"
    }
    else if (match.path.search("foodtruck") != -1)
    {
      section_category = "foodtruck"
    }
    else if (match.path.search("mtp") != -1)
    {
      section_category = "mtp"
    }
    else if (match.path.search("śniadania") != -1)
    {
      section_category = "breakfast"
    }
    else if (match.path.search("okazjonalne") != -1)
    {
      section_category = "special"
    }
    else if (match.path.search("delikatesy") != -1)
    {
      section_category = "delicatese"
    }
    else if (match.path.search("napoje") != -1)
    {
      section_category = "drink"
    }
    
    setSection(section_category)

    fetchData(setStoresOpenAndDeliver, setClosedStores, setStoresOpenAndTooFar, lat, lon, history, setLists, section_category)
    document.title = "Co tu zamówić..."   
    clearBasket()   
    checkDeviceType(history)
    // TagManager.dataLayer(tagManagerArgs)

    function visibilitychange(e)
    {
         if (!document.hidden)
         {
              fetchData(setStoresOpenAndDeliver, setClosedStores, setStoresOpenAndTooFar, lat, lon, history, setLists, section_category)
         }
    }
    if (typeof window !== 'undefined' && window.addEventListener) 
    {
        window.removeEventListener('visibilitychange', visibilitychange)
        window.addEventListener('visibilitychange', visibilitychange, false)
    }
    return () => {
         window.removeEventListener('visibilitychange', visibilitychange)
       }
  }, [])

  

  function GoToStore(store_object_id)
  {
    // event.preventDefault();
    localStorage.setItem("current_store_object_id", store_object_id)
    history.push('/store/'+store_object_id)
  }

  function onSearchChange(phrase)
  {
    let _items = []
    if (phrase)
    {
      for (let i = 0; i < storesOpenAndDeliver.length; i++) 
      {
        if (storesOpenAndDeliver[i].name.toLowerCase().search(phrase.toLowerCase()) !== -1)
        {
          _items.push({
            name:storesOpenAndDeliver[i].name,
            logo_image_url:storesOpenAndDeliver[i].logo_image_url,
            is_open: storesOpenAndDeliver[i].is_open,
            id: storesOpenAndDeliver[i].id,
            label: "Otwarte"
          })
        }
      }
      for (let i = 0; i < storesOpenAndTooFar.length; i++) 
      {
        if (storesOpenAndTooFar[i].name.toLowerCase().search(phrase.toLowerCase()) !== -1)
        {
          _items.push({
            name:storesOpenAndTooFar[i].name,
            logo_image_url:storesOpenAndTooFar[i].logo_image_url,
            is_open: storesOpenAndTooFar[i].is_open,
            id: storesOpenAndTooFar[i].id,
            label: "Otwarte | tylko odbiór"
          })
        }
      }
      for (let i = 0; i < storesClosed.length; i++) 
      {
        if (storesClosed[i].name.toLowerCase().search(phrase.toLowerCase()) !== -1)
        {
          _items.push({
            name:storesClosed[i].name,
            logo_image_url:storesClosed[i].logo_image_url,
            is_open: storesClosed[i].is_open,
            id: storesClosed[i].id,
            label: "Zamknięte"
          })
        }
      }
    }
    setSearchResults(_items)
  }

  function SetAddress()
  {
    history.push('/start')
  }

  if (lat == null && lon == null)
  {
      SetAddress()
  }

  return (
    <>
        <Navbar SetAddress={SetAddress} showAddressBar={true} showUserOptions={true} showSearch={true} searchResults={searchResults} onSearchChange={onSearchChange} />
        <NavbarSpace/>
        <Container maxWidth="lg" style={{minHeight:"1200px"}}>
            <NewBreadcrumbs section={section}/>
            <HomeProducts data = {lists} goToStore = {GoToStore} />
            <h2>Otwarte</h2>
            <Stores data = { storesOpenAndDeliver } goToStore = { GoToStore } key_name = {"opened"} />
            <h2>Otwarte | tylko odbiór</h2>
            <Stores data = { storesOpenAndTooFar } goToStore = { GoToStore } key_name = {"out_of_range"} />
            <h2>Zamknięte</h2>
            <Stores data = { storesClosed } goToStore = { GoToStore } key_name = {"closed"} />
        </Container>
        <FooterZio/>
    </>
    )

}

