import {useState, useEffect} from 'react';
import { isCustomStoreAvailable } from "../services/store"
import { useHistory  } from "react-router-dom"
import { Navbar, NavbarSpace, NavbarBackBtn } from "../components/navbar"
import { Container  } from '@material-ui/core';
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import {color_accent} from "../styles/globalstyle"
import { clearBasket } from "../services/basket"
import { getCurrentStoreData } from "../services/store"

const TextContainer = styled.div`
    text-align: center;
    margin-top: 80px;
    box-shadow: 0px 0px 2px 0px rgb(0,0,0,.1);
    border-radius:6px;
    padding: 16px;
`

const Title = styled.h1`
    font-size: 60px;
    color:${color_accent};
`

const ButtonContainer = styled.div`
    margin-top: 60px;
    padding-bottom: 40px;
`


export default function ThankYou() {

    const history = useHistory();
    const [thankYouDescription, setThankYouDescription] = useState("Każde zamówienie to wsparcie dla twoich ulubionych restauracji, piekarni i lokalnych biznesów.")

    const store_data = getCurrentStoreData()

    function goToOrders()
    {
        history.push('/orders')
    }

    useEffect(() => {
        document.title = "dziękujemy"  
        clearBasket()
        console.log(store_data)
        if (store_data != null && store_data.thank_you_description != null)
        {
            if (store_data.thank_you_description)
            {
                setThankYouDescription(store_data.thank_you_description)
            }
        }
    }, [])

  return (
        <>
        {isCustomStoreAvailable()?<>
            <NavbarBackBtn storeData={ {logo_image_url:localStorage.getItem("custom_store_logo_image_url")} }/>
                <NavbarSpace/>
                <Container>
                    <TextContainer>

                        <Title>Dziękujemy</Title>

                        <h1>za twoje zamówienie:</h1>
                        <h4>{thankYouDescription}</h4>

                        <h2>Zapraszamy ponownie</h2>
                        <ButtonContainer>
                            <Button variant="contained" color="primary" onClick={goToOrders} >
                                Moje zamówienia
                            </Button>
                        </ButtonContainer>
                    </TextContainer>

                </Container>
            </>:<><Navbar showBack={true} showIcon={true} showAddressBar={true} showUserOptions={true} /><NavbarSpace/>
                <Container>
                    <TextContainer>

                        <Title>Dziękujemy</Title>

                        <h1>że zamawiasz przez ZiO food.</h1>
                        <h4>{thankYouDescription}</h4>


                        <h2>Dziękujemy i zapraszamy ponownie</h2>
                        <ButtonContainer>
                            <Button variant="contained" color="primary" onClick={goToOrders} >
                                Moje zamówienia
                            </Button>
                        </ButtonContainer>
                    </TextContainer>

                </Container>
            </> }
        </>
  )
}

