import Parse from "parse"
import React, {useState, useEffect} from 'react';
import { useHistory  } from "react-router-dom"
import { Navbar, NavbarSpace, NavbarBackBtn } from "../components/navbar"
import styled from "styled-components"
import { ProductName, ProductPrice } from "../components/menu"
import { useBasket } from "../services/basket"
import { CheckoutInfoRow, CheckoutSelectRow, CheckoutDeliveryNotesRow, CheckoutAddressRow, CheckoutPhoneNumberRow, CheckoutButtonRow, CheckoutNoteRow, CheckoutCustomerName } from "../components/checkout"
import { getUserObjectId, getUserLocation, setUserPrimaryDeliveryAddress, isLoggedIn, getUserData, updateUserBusinessInformation } from "../services/user"
import { LoginDialog } from "../components/login_dialog"
import { getCurrentStoreData, isStoreWithinRange, getReturnUrl, getReturnErrorUrl, getCurrentStoreDataKey } from "../services/store"
import { getStoreDistance } from "../services/store"
import { getMultiItemDiscount } from "../services/promotion"
import { getPromocodeDiscount } from "../services/promocode"
import { clearBasket } from "../services/basket"
import { getDeliveryPrice } from "../services/delivery"
import { getDistance } from "../services/helper"

import DeleteIcon from '@material-ui/icons/Delete';

import { AlertDialog, StoreRegulationDialog, AddressSelectDialog } from "../components/dialogs"
import { Loader } from "../components/loader"
import Switch from '@material-ui/core/Switch';

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';



const Title = styled.div`
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 28px;
`

const SubTitle = styled.div`
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 22px;
    margin-top:20px;
    margin-bottom:8px;
`

const Text = styled.div`
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 18px;
`

const SubText = styled.div`
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: gray;
    margin-top:6px;
`

const SwitchRow = styled.div`
    display: grid;
    grid-template-columns: auto 50px;
    height: 40px;
`

const SummaryContainer = styled.div`
    padding-top: 12px;
`

const BasketContainer = styled.div`
   
`



const ProductsContainer = styled.div`
   padding-bottom: 6px;
   padding-top: 6px;
`

const ProductItem = styled.div`
    display: grid;
    grid-template-columns: 32px auto 70px 30px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 18px;
`

const InfoItem = styled.div`
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    font-weight: 800;
    font-size: 18px;
`

const OptionsItem = styled.div`
    padding: 4px 0px;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    color: gray;
    font-weight: 200;
`


function roundPrice(price)
{
    return Math.round( price * 100 ) / 100
}

async function getCompletionDates(store_object_id, setAvailableCompletionDates, updateComplationDate)
{
    const timestamp = Date.now()
    const OrderSchedule = Parse.Object.extend("OrderSchedule");
    const query = new Parse.Query(OrderSchedule);
    query.equalTo("isActive", true)
    query.greaterThan("expirationDate", timestamp/1000);
    query.equalTo("storeObjectId", store_object_id)
    query.ascending("completionTime");
    query.include("availabilityTimes")
    query.limit(24)
    const results = await query.find();

    let items = []
    for (let i = 0; i < results.length; i++) 
    {
        const tag = results[i].get("tag")
        if (tag === "days")
        {
            const available_dates = await Parse.Cloud.run("getAvailableDeliveryDaysForScheduledOrder", {object_id: results[i].id})
            for (let j = 0; j < available_dates.length; j++)
            {
                items.push(available_dates[j])
            }
        }
        else
        {
            items.push({
                name: results[i].get("name"),
                object_id: results[i].id,
                tag: results[i].id,
                object_tag: results[i].get("tag"),
                completion_offset: results[i].get("completionOffset"),
                completion_time: results[i].get("completionTime"),
            })
        }
    }
    if (items.length === 0)
    {
        items.push({name:"brak dostepnych dat"})
    }
    else  
    {
        updateComplationDate(items[0].tag, items, 0)
    }
    setAvailableCompletionDates(items)
}



async function redeemPromocode(promocode, setPromocodeWarrningMessage, setPromocodeSuccessMessage)
{
    let result = await Parse.Cloud.run("redeemPromoCode", {
        code: promocode.toLowerCase(),
        device_id: getUserObjectId()
    }).catch(error => {
           console.log(error);
           setPromocodeWarrningMessage("kod się przedawnił lub jest błędny")
       });

    if (result)
    {
        // getPromocodes(setPromocodes)
        setPromocodeSuccessMessage("kod promocyjny dodany!")
    }
    return result
}

async function getStoreRegulation(setShowLoader, setOpenStoreRegulation, setRegulationContent)
{
    setShowLoader(true)
    const CustomData = Parse.Object.extend("CustomData");
    const query = new Parse.Query(CustomData);
    const result = await query.get("mivsXKYXEG")//("OGTrJWM0gG") 
    const _content = result.get("data_array")

    const store_data = getCurrentStoreData()

    const _business_name = store_data.address_information.business_information
    const _store_full_name = store_data.name
    const _email = store_data.address_information.email
    const _distance = store_data.delivery_information.distance.toString()

    for (var i = 0; i < _content.length; i++) 
    {
        _content[i].text = _content[i].text.replace('_business_information_', _business_name)
        _content[i].text = _content[i].text.replace('_store_information_', _store_full_name)
        _content[i].text = _content[i].text.replace('_email_', _email)
        _content[i].text = _content[i].text.replace('_distance_', _distance)
    }

    setRegulationContent(_content)
    setOpenStoreRegulation(true)    
    setShowLoader(false)
}

async function onlinePayment(store_user_object_id, description, order_object_id, setShowLoader)
{
    const request_params = {
        storeUserObjectId: store_user_object_id,
        description: description,
        crc: order_object_id,
        return_url: getReturnUrl(),
        return_error_url: getReturnErrorUrl(),
        platform: "web"
    }
    const result = await Parse.Cloud.run("getPaymentUrl", request_params).catch(error => {
           console.log(error);
           setShowLoader(false)
       });

    setShowLoader(false)
    if (result.message)
    {
        // online payment.
        window.open(result.message, '_self');
    }
    else
    {
        console.log("error")
    }
}

function returnTotalProductsCost(basket)
{
    let value = basket.reduce((total, order) => {
        if (order.selected_options != null)
        { 
            for (var i = 0; i < order.selected_options.length; i++) 
            {
                total = total + order.selected_options[i].price
            }
        }
        return total + (order.price*order.quantity)
    }, 0)
    return Math.round(value*100)/100
}

function generate_order_id(str)
{
   const chars = '0123456789ABCDEF'.split('');

   let uuid = [], rnd = Math.random, r;
   if (str)
   {
        uuid[0] = str; // version 4
   }

   for (let i = 0; i < 6; i++)
   {
      if (!uuid[i])
      {
         r = 0 | rnd()*16;

         uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r & 0xf];
      }
   }

   return uuid.join('');
}

async function order(basket, order_params, setShowLoader, setAlert, history, defaultZioUi)
{
    let _order_id = generate_order_id()
    let date = new Date();
    let timeNow = Math.floor( date.getTime()*0.001 );
    const store_data = getCurrentStoreData()
    let items_object_ids = []

    for (var i = 0; i < basket.length; i++) 
    {
        items_object_ids.push({
            objectId: basket[i].id,
            quantity: basket[i].quantity,
            modifier: basket[i].modifier
        })
        if (basket[i].selected_options)
        {
            for (var j = 0; j < basket[i].selected_options.length; j++) 
            {
                 items_object_ids.push({
                    objectId: basket[i].selected_options[j].id,
                    quantity: 1,
                    modifier_tag: basket[i].selected_options[j].modifier_tag
                })
            }
        }
    }

    const _user_data = getUserData()
    const _user_address = _user_data.get("address")
    const _user_misc = _user_data.get("misc")

    let _customerInfo = {
        street: _user_address.street, 
        flat_number: _user_address.flat_number,
        building_number: _user_address.building_number, 
        phone_number: _user_address.phone_number, 
        city: _user_address.city, 
        latitude: _user_address.latitude,
        longitude: _user_address.longitude,
        notes: _user_address.notes,
        postcode: _user_address.postcode,
        user_name: _user_address.user_name,
        store_regulation_accepted: true,
    }

    if (_user_misc)
    {
        if (_user_misc.business_information)
        {
            _customerInfo.business_information = _user_misc.business_information
        }
    }

    const request_params = { 
        customerObjectId: getUserObjectId(),
        storeUserObjectId: store_data.store_user_object_id,
        orderId: _order_id.toString() ,
        orderTime: timeNow,
        messageFromCustomer: [],
        deliveryType: order_params.deliveryType,
        deliveryPrice: order_params.deliveryPrice,
        storeObjectId: store_data.object_id,
        completionTime: order_params.completionTime,
        onlinePayment: order_params.onlinePayment,
        itemObjectId: items_object_ids,
        paymentMethod: order_params.paymentMethod,
        promo_code_object_id: "",
        deliveryInformation: {
            distance: 0,
            drop_time: 0,
            pickup_time: 0,
            possible_pickup_time: 0,
            delivery_duration: 0
        },
        schedule_object_id: order_params.schedule_object_id,
        repeat_information: "",
        promo_code_object_id: order_params.promo_code_object_id,
    }

    if (order_params.message_from_customer && order_params.message_from_customer.message !== "")
    {
        order_params.message_from_customer.time = timeNow
        request_params.messageFromCustomer.push(order_params.message_from_customer)
    }

    if (defaultZioUi)
    {
        request_params.platform = "web"
    }
    else  
    {
        request_params.platform = "web_custom"
    }

    if (order_params.deliveryType === "delivery")
    {
        request_params.deliveryInformation.distance = store_data.distance
        request_params.deliveryInformation.delivery_duration = Math.round(store_data.distance*10*30)
    }

    if (order_params.completionTime > 0)
    { 
        request_params.deliveryInformation.pickup_time = order_params.completionTime - request_params.deliveryInformation.delivery_duration
        request_params.deliveryInformation.possible_pickup_time = request_params.deliveryInformation.pickup_time
        request_params.deliveryInformation.drop_time = order_params.completionTime
    }

    if (order_params.invoice_required)
    {
        _customerInfo.business_information = order_params.business_information
    }

    request_params.customerInfo = _customerInfo

    setShowLoader(true)
    let result = await Parse.Cloud.run("order", request_params).catch(error => {
           console.log(error.message);
           if (error)
           {
                if (error.message === "store_is_closed")
                {
                    clearBasket()
                    setAlert({open:true, title: "Lokal jest zamknięty", description: "przykro nam ale lokal jest już zamknity i nie przyjmuje zamówień.", callback: history.goBack })
                }
                else if (error.message === "product_not_available")
                {
                    clearBasket()
                    setAlert({open:true, title: "Produkt niedostępny", description: "przykro nam ale jeden z produktów już nie jest dostępny. Złóż zamówienie ponownie z listy dostępnych produktów.", callback: history.goBack })
                }
                else if (error.message === "delivery_not_available")
                {
                    clearBasket()
                    setAlert({open:true, title: "Brak kuriera", description: "Przykro nam ale lokal obecnie niema dostępnego kuriera w twoim rejonie. Prosimy spróbować później.", callback: history.goBack })
                }
           }
       });
    
    if (result && result.id)
    {
        // online payment.
        if (order_params.onlinePayment)
        {
            onlinePayment(store_data.store_user_object_id, request_params.orderId, result.id, setShowLoader)
        }
        else  
        {
            history.push({
                pathname: '/thankyou'
            })            
        }
    }
    else
    {
        setShowLoader(false)
        console.log("error")
    }

    // console.log(order_params)
}

export default function Checkout() {
    const basketHook = useBasket()
    const history = useHistory();

    const store_data = getCurrentStoreData()

    const [openAddressDialog, setOpenAddressDialog] = useState(false)

    const [defaultZioUi, setDefaultZioUi] =  useState(localStorage.getItem("custom_store_object_id")===null||localStorage.getItem("custom_store_object_id")===""?true:false)

    const [showLoader, setShowLoader] = useState(false)

    const [totalProductsCost, setTotalProductsCost] = useState(0) // cost of products, without dicsounts and promos.
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [remainingMinimumOrderValue, setRemainingMinimumOrderValue] = useState(0)
    const [freeDeliveryFrom, setFreeDeliveryFrom] = useState(0)

    const [selectedPromocode, setSelectedPromocode] = useState({})
    const [selectedPromocodeName, setSelectedPromocodeName] = useState("")
    const [availablePromocodes, setAvailablePromocodes] = useState([])
    const [promoDiscountValue, setPromoDiscountValue] = useState(0)
    const [promocodeDiscountValue, setPromocodeDiscountValue] = useState(0)

    // delivery note
    const [deliveryNote, setDeliveryNote] = useState(localStorage.getItem("primary_address_notes"))

    // order note
    const [orderNote, setOrderNote] = useState("")

    // Delivery
    const [selectedDeliveryType, setSelectedDeliveryType] = useState()
    const [selectedDeliveryTypeName, setSelectedDeliveryTypeName] = useState("")
    const [availableDeliveryTypes, setAvailableDeliveryTypes] = useState({})


    // payment
    const [paymentMethodName, setPaymentMethodName] = useState("BLIK, szybki przelew")
    const [paymentMethod, setPaymentMethod] = useState("online")
    const [onlinePayment, setOnlinePayment] = useState(true)

    // completion types
    const [selectedCompletionType, setSelectedCompletionType] = useState("")
    const [selectedCompletionTime, setSelectedCompletionTime] = useState(0)
    const [selectedCompletionName, setSelectedCompletionName] = useState("")
    const [availableCompletionTypes, setAvailableCompletionTypes] = useState([]) //store_data.completion_types_list)
    
    const [availableCompletionDates, setAvailableCompletionDates] = useState([])
    const [selectDateName, setSelectDateName] = useState("")
    const [selectedDateObjectId, setSelectedDateObjectId] = useState("")
    
    const [showSelectTime, setShowSelectTime] = useState(false)

    const [availableCompletionTimes, setAvailableCompletionTimes] = useState([])
    const [selectTimeName, setSelectTimeName] = useState("")

    
    
    // delivery address
    const [userName, setUserName] = useState("")
    const [addressCity, setAddressCity] = useState("")
    const [addressStreet, setAddressStreet] = useState("")
    const [addressBuilding, setAddressBuilding] = useState("")
    const [addressFlat, setAddressFlat] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [requirePhoneNumber, setRequirePhoneNumber] = useState(store_data.require_phone_number)
    const [requireUserName, setRequireUserName] = useState(store_data.require_user_name)

    const [addressLabel, setAddressLabel] = useState("")


    const [addresses, setAddresses] = useState([])

    const [alert, setAlert] = useState({open:false, title:"", description:""})

    const [openStoreRegulation, setOpenStoreRegulation] = useState(false)
    const [regulationContent, setRegulationContent] = useState([])

    const [canOrder, setCanOrder] = useState(true)

    const [promocodeWarrningMessage, setPromocodeWarrningMessage] = useState("")
    const [promocodeSuccessMessage, setPromocodeSuccessMessage] = useState("")
    const [newPromoCode, setNewPromoCode] = useState("")

    // business information
    const [invoiceRequired, setInvoiceRequired] = useState(false)
    const [businessInformation, setBusinessInformation] = useState("")

    let order_params = {
        deliveryType: selectedDeliveryType,
        deliveryPrice: deliveryPrice,
        completionTime: selectedCompletionTime,
        paymentMethod: paymentMethod,
        onlinePayment: onlinePayment,
        promo_code_object_id: selectedPromocode.object_id,
        message_from_customer: {message: orderNote, time: 0, tag: "message", seen: false},
        schedule_object_id: selectedDateObjectId,
        invoice_required: invoiceRequired,
        business_information: businessInformation
    }


    function updateOrderNote(event) 
    {
        event.preventDefault();
        setOrderNote(event.target.value)
    }

    function getDefaultDeliveryType() // return available and default delivery type
    {
        if (store_data.delivery_type.delivery === true)
        {
            return "delivery"
        }
        else  
        {
            return "collect"
        }
    }
    
    async function getPromocodes(store_object_id)
    {
        var timestamp = Date.now()
        const PromoCodeRedeemed = Parse.Object.extend("PromoCodeRedeemed");
        const query = new Parse.Query(PromoCodeRedeemed);
        query.include("parent")
        query.greaterThan("stock", 0)
        query.greaterThan("expirationDate", timestamp/1000);
        query.equalTo("target", store_object_id)
        const results = await query.find();
        let items = [{name:"nie używaj kodu promocyjnego", object_id:"", tag:"none"}]
        for (let i = 0; i < results.length; i++) 
        {
            let _name = results[i].get("parent").get("name")
            if (results[i].get("parent").get("deliveryType") === "delivery")
            {
                _name = _name + " (dostawie)"
            }
            else if (results[i].get("parent").get("deliveryType") === "collect")
            {
                _name = _name + " (odbiór osobisty)"
            }
            items.push({
                name: _name,
                object_id: results[i].id,
                tag: results[i].id,
                promo_code_tag: results[i].get("parent").get("tag"),
                discount_target: results[i].get("parent").get("discountTarget"),
                value: results[i].get("parent").get("value"),
                discount_type: results[i].get("parent").get("discountType"),
                delivery_type: results[i].get("parent").get("deliveryType")
            })
        }
        setAvailablePromocodes(items)
        if (items.length > 1)
        {
            updateSelectedPromocode(items[1].tag, items)
            let _selected_delivery_type = selectedDeliveryType?selectedDeliveryType:getDefaultDeliveryType()

            const _delivery_price = updateDeliveryPrice(_selected_delivery_type)
            setPromocodeDiscountValue( roundPrice( getPromocodeDiscount(items[1], _selected_delivery_type, returnTotalProductsCost(basketHook.basket), _delivery_price ) ) )
        }
    }

    function updateRemainingMinimumOrderValue(delivery_type, basket_data)
    {
        if (delivery_type === "collect")
        {
            setRemainingMinimumOrderValue(0)
        }
        else if (delivery_type === "delivery")
        {
            setRemainingMinimumOrderValue(roundPrice(store_data.minimum_order_value - returnTotalProductsCost(basket_data?basket_data:basketHook.basket) ) )
        }
    }


    function updateFreeDeliveryFromOrderValue()
    {
        if (store_data.delivery_information.free_from_order_value && store_data.delivery_information.free_from_order_value.is_active === true && store_data.delivery_information.free_from_order_value.value > 0)
        {
            setFreeDeliveryFrom(store_data.delivery_information.free_from_order_value.value)
        }
        else  
        {
            setFreeDeliveryFrom(0)
        }
    }

    function updateDeliveryPrice(tag, order_value)
    {
        if (tag === "collect")
        {
            setDeliveryPrice(0)
            return 0
        }
        else if (tag === "delivery")
        {
            let _order_value = order_value?order_value:(totalProductsCost - promoDiscountValue - promocodeDiscountValue)
            let _delivery_price = getDeliveryPrice(store_data.delivery_information, store_data.distance).price
            if (store_data.delivery_information.free_from_order_value && store_data.delivery_information.free_from_order_value.is_active === true && store_data.delivery_information.free_from_order_value.value > 0)
            {
                if ( _order_value >= store_data.delivery_information.free_from_order_value.value)
                {
                    _delivery_price = 0
                }
            }
            updateFreeDeliveryFromOrderValue()
            setDeliveryPrice(_delivery_price)

            for (let i = 0; i < basketHook.basket.length; i++) 
            {
                if (basketHook.basket[i].tag === "free_delivery")
                {
                    setDeliveryPrice(0) 
                     _delivery_price = 0
                }
            }
            return _delivery_price
        }
        updateRemainingMinimumOrderValue(tag)
    }

    function updateDeliveryType(tag)
    {
        setSelectedDeliveryType(tag)
        const _delivery_price = updateDeliveryPrice(tag)
        for (let i = 0; i < store_data.delivery_types_list.length; i++) 
        {
            if (tag === store_data.delivery_types_list[i].tag)
            {
                setSelectedDeliveryTypeName(store_data.delivery_types_list[i].name)
            }
        }
        const _promo = getMultiItemDiscount(basketHook.basket, tag)
        setPromoDiscountValue(_promo.discount)
        setPromocodeDiscountValue( roundPrice( getPromocodeDiscount( selectedPromocode, tag, returnTotalProductsCost(basketHook.basket), _delivery_price ) ) )
    }

    function updateComplationDate(tag, items, index)
    {
        let _items = []
        if (items != null)
        {
            _items = items
        }
        else  
        {
            _items = availableCompletionDates
        }
        for (let i = 0; i < _items.length; i++) 
        {
            if (tag === _items[i].tag)
            {
                setSelectedDateObjectId(_items[i].object_id)
                setSelectDateName(_items[i].name)
                setShowSelectTime(false)
                if (_items[i].object_tag === "next_day") // calculate completion time properly
                {
                    let date = new Date();
                    const timeNow = Math.floor( date.getTime()*0.001 );

                    let _offset = 0//app.stores.getOpenDayOffset(_store.openingHours, results[i].completionOffset)
                    _offset = _offset + _items[i].completion_offset
                    setSelectedCompletionTime(timeNow + 60*60*24 * _offset)
                }
                else if (_items[i].object_tag === "date") // calculate completion time properly
                {
                    setSelectedCompletionTime(items[i].completion_time)
                }
                else if (_items[i].object_tag === "days") // calculate completion time properly
                {
                    let _times = []
                    for (let j = 0; j < _items[i].times.length; j++)
                    {
                        const date = new Date(_items[i].times[j]*1000);
                        let _min = "0" + date.getMinutes()
                        _times.push({name: date.getHours() + ":" + _min.substr(-2), tag: _items[i].times[j]})
                    }
                    setAvailableCompletionTimes(_times)
                    setSelectTimeName(_times[0].name)
                    setSelectedCompletionTime(_times[0].tag)
                    setShowSelectTime(true)
                }
                break
            }
        }
    } 

    function updateComplationTime(value)
    {
        setSelectedCompletionTime(value)
        const date = new Date(value*1000);
        let _min = "0" + date.getMinutes()
        setSelectTimeName(date.getHours() + ":" + _min.substr(-2))
    }

    async function updateComplationType(tag)
    {
        setShowSelectTime(false)
        setSelectedCompletionType(tag)
        for (let i = 0; i < store_data.completion_types_list.length; i++) 
        {
            if (tag === store_data.completion_types_list[i].tag)
            {
                setSelectedCompletionName(store_data.completion_types_list[i].name)
            }
        }

        if (tag === "schedule")// get dates
        {   
            getCompletionDates(store_data.object_id, setAvailableCompletionDates, updateComplationDate)
        }
        else if (tag === "today")
        {
            const available_times = await Parse.Cloud.run("getAvailableDeliveryTimesForScheduledOrder", {store_object_id: store_data.object_id})
            console.log(available_times)
            let _times = []
            for (let i = 0; i < available_times.length; i++)
            {
                const date = new Date(available_times[i]*1000);
                let _min = "0" + date.getMinutes()
                _times.push({name: date.getHours() + ":" + _min.substr(-2), tag: available_times[i]})
            }
            if (_times.length > 0)
            {
                setShowSelectTime(true)
                setAvailableCompletionTimes(_times)
                setSelectTimeName(_times[0].name)
                setSelectedCompletionTime(_times[0].tag)
                setSelectedDateObjectId("4p3Qfd1wgP") // constand value for today orders
            }
        }
        else  
        {
            setSelectedCompletionTime(0)
            setSelectedDateObjectId("")
        }
    }


    function updateSelectedPromocode(tag, items)
    {
        let index = 0
        let _items = []
        if (items)
        {
            _items = items
        }
        else  
        {
            _items = availablePromocodes
        }

        for (let i = 0; i < _items.length; i++) 
        {
            if (tag === _items[i].tag)
            {
                setSelectedPromocodeName(_items[i].name)
                setSelectedPromocode(_items[i])

                index = i
                break
            }
        }

        let _selected_delivery_type = selectedDeliveryType?selectedDeliveryType:getDefaultDeliveryType()
        setPromocodeDiscountValue( roundPrice( getPromocodeDiscount(_items[index], _selected_delivery_type, returnTotalProductsCost(basketHook.basket), deliveryPrice ) ) )
    }

    function updateBusinessInfo(event) 
    {
        event.preventDefault();
        setBusinessInformation(event.target.value)
        updateUserBusinessInformation(event.target.value)
    }

    function updateDeliveryNote(event)
    {
        event.preventDefault();
        setDeliveryNote(event.target.value)
        localStorage.setItem("primary_address_notes", event.target.value)
        setUserPrimaryDeliveryAddress()
    }

    function updateDeliveryAddress(event)
    {
        event.preventDefault();
        if (event.target.id === "city")
        {
            setAddressCity(event.target.value)
        }
        else if (event.target.id === "street")
        {
            setAddressStreet(event.target.value)
        }
        else if (event.target.id === "building")
        {
            setAddressBuilding(event.target.value)
        }
        else if (event.target.id === "flat")
        {
            setAddressFlat(event.target.value)
        }
    }

    function onAddressClose()
    {
        // check adres via api.
        setAddresses([])
        getUserLocation(addressCity, addressStreet, addressBuilding, setAddresses)
    }

    function onAddressSelect(address_selected)
    {
        // set address on user. 
        // console.log(address_selected)
        setAddressCity(address_selected.city)
        setAddressStreet(address_selected.street)
        setAddressBuilding(address_selected.building_number)
        setAddressLabel(address_selected.city + ", " + address_selected.street + " " + address_selected.building_number + " / " + addressFlat)
        localStorage.setItem("primary_address_city", address_selected.city)
        localStorage.setItem("primary_address_street", address_selected.street)
        localStorage.setItem("primary_address_building_number", address_selected.building_number)
        localStorage.setItem("primary_address_postcode", address_selected.postcode)
        localStorage.setItem("primary_address_latitude", address_selected.latitude)
        localStorage.setItem("primary_address_longitude", address_selected.longitude)
        localStorage.setItem("primary_address_postcode", address_selected.postcode)
        store_data.distance = getStoreDistance()
        updateDeliveryPrice(selectedDeliveryType, returnTotalProductsCost(basketHook.basket) - promoDiscountValue - promocodeDiscountValue)
        setUserPrimaryDeliveryAddress()

        if (isStoreWithinRange() === false)
        {
            setAlert({open:true, title:"UWAGA", description:"niestety ale jesteś poza zasigiem dostaw."} )
            setCanOrder(false)
        }
        else  
        {
            setCanOrder(true)
        }
    }

    function onOpenAddressDialog()
    {
        setAddressCity("")
        setAddressStreet("")
        setAddressBuilding("")
        setAddressFlat("")
    }

    function updatePhonenumber(event)
    {
        event.preventDefault();    
        setPhoneNumber(event.target.value.toString())
    }

    function onPhonenumberClose()
    {
        if (phoneNumber.length === 9)
        {
            setPhoneNumber("48" + phoneNumber.toString())
            localStorage.setItem("primary_address_phone_number", "48" + phoneNumber.toString())
            setUserPrimaryDeliveryAddress()
        }
        else if (phoneNumber.length === 11)
        {
            localStorage.setItem("primary_address_phone_number", phoneNumber.toString())
            setUserPrimaryDeliveryAddress()
        }
        else 
        {
            alert("Numer wydaje się błędny, sprawdź i popraw.");
        }
    }

    // User Name
    function updateUserName(event)
    {
        event.preventDefault();    
        localStorage.setItem("user_name", event.target.value)
        setUserName(event.target.value)
    }

    function onUserNameClose()
    {
        setUserPrimaryDeliveryAddress()
    }


    function callbackAfterLogin()
    {
        const _user_data = getUserData()
        const _user_address = _user_data.get("address")
        setPhoneNumber(_user_address.phone_number)
        setAddressCity(_user_address.city)
        setAddressStreet(_user_address.street)
        setAddressBuilding(_user_address.building_number)
        setAddressFlat(_user_address.flat_number)
        setUserName(_user_address.user_name)
        if (_user_address.business_information)
        {
            setBusinessInformation(_user_address.business_information)
        }

        if (_user_address.city !== "" && _user_address.street !== "" && _user_address.building_number !== "")
        {
            setAddressLabel(_user_address.city + ", " + _user_address.street + " " + _user_address.building_number + " / " + _user_address.flat_number)
        }
        setDeliveryNote(_user_address.notes)
        // getPromocodes(store_data.object_id)
    }

    function callbackAfterCancel()
    {
        history.goBack()
    }

    function checkMinimumOrderValue(selected_delivery_type)
    {
        if (selected_delivery_type === "delivery")
        { 
            const _order_value = returnTotalProductsCost(basketHook.basket)

            if (selectedPromocode !== null && selectedPromocode.promo_code_tag !== null && selectedPromocode.promo_code_tag === "voucher")
            {
                _order_value = _order_value - selectedPromocode.value
            }
            if (_order_value >= store_data.minimum_order_value)
            {
                return true
            }
            else 
            {
                return false
            }
        }
    }

    function checkIfCanOrder()
    {
        let _can_order = true
        if (selectedDeliveryType === "delivery")
        {
            if (isStoreWithinRange() === true)
            {
                if (addressStreet !== "" && addressBuilding !== "" && phoneNumber !== "")
                {
                    // all set, ok.
                }
                else  
                {
                
                    if (requirePhoneNumber && phoneNumber === "") // phone is required and it is missing. warrning
                    { 
                        // Alert("adres i numer telefonu są wymagane dla zamówień z dostawą.") 
                        setAlert({open:true, title: "Dostawa", description: "numer telefonu jest wymagany dla zamówień z dostawą." })
                        _can_order = false
                    }
                    else if (addressStreet === "" && addressBuilding === "") // address is missing
                    {
                        // Alert("adres i numer telefonu są wymagane dla zamówień z dostawą.") 
                        setAlert({open:true, title: "Dostawa", description: "adres jest wymagany dla zamówień z dostawą." })
                        _can_order = false
                    }
                }   
            }
            else 
            {
                // Alert("Jesteś zbyt daleko, dostawa nie jest możliwa. Podaj inny adres, lub wybierz inny lokal") 
                setAlert({open:true, title: "Adres", description: "Jesteś zbyt daleko, dostawa nie jest możliwa. Podaj inny adres." })
                _can_order = false 
            }

            if (_can_order && !checkMinimumOrderValue(selectedDeliveryType)) 
            {
                // Alert("kwota minimalna nie została osiągnięta.") 
                setAlert({open:true, title: "Kwota", description: "kwota minimalna nie została osiągnięta." })
                _can_order = false
            }
        }
        else  
        {
            if (requirePhoneNumber)
            {
                if (phoneNumber === "" || userName === "")
                {
                    let _str = ""
                    if (phoneNumber === "" && userName === "")
                    {
                        _str = "numer telefonu oraz imię i  nazwisko są wymagane "
                    }
                    else if (userName === "")
                    {
                        _str = "imię i  nazwisko jest wymagany "
                    }
                    else if (phoneNumber === "")
                    {
                        _str = "numer telefonu jest wymagany "
                    }
                    _str = _str + " dla zamówień z odbiorem osobistym."
                    setAlert({open:true, title: "Uzupełnij informacje", description: _str })
                    _can_order = false
                }
            }
        }

        if (selectedCompletionType === "schedule")
        {
            if (selectedDateObjectId === "")
            {
                _can_order = false
                setAlert({open:true, title: "Brak dostępnych terminów", description: "Lokal niema obecnie dostępnych terminów dla planowych zamówień. Zadzwoń do lokalu w celu wyjaśnienia sytuacji." })
            }
        }

        if (_can_order)
        {
            order(basketHook.basket, order_params, setShowLoader, setAlert, history, defaultZioUi)
        }
    }

    function deleteItem(item)
    {
        let _basket = [...basketHook.basket]
        if (item.quantity > 1)
        {
            for (let i = 0; i < _basket.length; i++)
            {
                if (_basket[i].uid == item.uid)
                {
                    _basket[i].quantity = _basket[i].quantity - 1
                    break
                }
            }
        }
        else  
        {
            for (let i=0; i < _basket.length; i++)
            {
                if (_basket[i].uid === item.uid)
                {
                    _basket.splice(i, 1)
                    break
                }
            }
        }
        basketHook.setBasket(_basket)

        if (returnTotalProductsCost(_basket) === 0)
        {
            history.goBack()
            return
        }
        
        setTotalProductsCost(returnTotalProductsCost(_basket))
        updateRemainingMinimumOrderValue(selectedDeliveryType, _basket)
        const _promo = getMultiItemDiscount(_basket, selectedDeliveryType)
        setPromoDiscountValue(_promo.discount)
        const _promo_code = roundPrice(getPromocodeDiscount(selectedPromocode, selectedDeliveryType, returnTotalProductsCost(_basket), deliveryPrice) )
        setPromocodeDiscountValue(_promo_code)
        updateDeliveryPrice(selectedDeliveryType, returnTotalProductsCost(_basket) - _promo.discount - _promo_code)
    }

    function updatePromocode(event)
    {
        console.log(event.target.value)
        event.preventDefault();
        setNewPromoCode(event.target.value)
    }

    async function onAddPromocodeClose()
    {
        const promo_code_result = await redeemPromocode(newPromoCode, setPromocodeWarrningMessage, setPromocodeSuccessMessage)
        if (promo_code_result && promo_code_result.id)
        {
            getPromocodes(store_data.object_id)
        }
    }

    function updatePaymentMethod(payment_method)
    {
        if (payment_method === "online")
        {
            setPaymentMethodName("BLIK, szybkie przelewy")
            setPaymentMethod("online")
            setOnlinePayment(true)
        }
        else if (payment_method === "card")
        {
            setPaymentMethodName("karta przy odbiorze")
            setPaymentMethod("card")
            setOnlinePayment(false)
        }
        else if (payment_method === "cash")
        {
            setPaymentMethodName("gotówka przy odbiorze")
            setPaymentMethod("cash")
            setOnlinePayment(false)
        }
        else //if (payment_method === "free")
        {
            setPaymentMethodName("bez opłat")
            setPaymentMethod("cash")
            setOnlinePayment(false)
        }
    }

    useEffect(() => {

        if (!store_data || !store_data.object_id)
        {
            history.push({
                pathname: '/'
            })
            return
        }

        // delivery type
        setAvailableDeliveryTypes(store_data.delivery_types_list)
        const _delivery_type = getDefaultDeliveryType()
        setSelectedDeliveryType(_delivery_type)
        document.title = "zamawiam"  

        if (store_data.delivery_type != null)
        {
            updateDeliveryType(_delivery_type)

            if (store_data.completion_type.asap)
            {
                updateComplationType("asap")
            }
            else if (store_data.completion_type.today) 
            { 
                updateComplationType("today")
            }
            else if (store_data.completion_type.schedule)
            {
                updateComplationType("schedule")
            }    
        }
        
        const _total_order_cost = returnTotalProductsCost(basketHook.basket)
        setTotalProductsCost(_total_order_cost)
        updateDeliveryPrice(_delivery_type, _total_order_cost) 
        setRemainingMinimumOrderValue(roundPrice(store_data.minimum_order_value - _total_order_cost))
        if (isLoggedIn())
        {
            getPromocodes(store_data.object_id)
            const _user_data = getUserData()
            const _user_address = _user_data.get("address")

            setAddressCity(_user_address.city)
            setAddressStreet(_user_address.street?_user_address.street:"")
            setAddressBuilding(_user_address.building_number?_user_address.building_number:"")
            setAddressFlat(_user_address.flat_number?_user_address.flat_number:"")
            setPhoneNumber(_user_address.phone_number?_user_address.phone_number:"")
            setUserName(_user_address.user_name?_user_address.user_name:"")
        }

        if (store_data.payment_method.online === true)
        {
            updatePaymentMethod("online")
        }
        else if (store_data.payment_method.cash === true)
        {
            updatePaymentMethod("cash")
        }
        else if (store_data.payment_method.card === true)
        {
            updatePaymentMethod("card")
        }
        else  
        {
            updatePaymentMethod()
        }

        setAvailableCompletionTypes(store_data.completion_types_list)

        window.scrollTo(0,0)
      }, [])

  return (
    <>
    {store_data!==null?<>
        <Loader show={showLoader}/>
        {defaultZioUi?<Navbar showBack={true} showAddressBar={false} showUserOptions={false} />:<NavbarBackBtn storeData={ {logo_image_url:localStorage.getItem("custom_store_logo_image_url")} } />}
        <NavbarSpace/>
        <LoginDialog callbackAfterLogin={callbackAfterLogin} callbackIfLoggedin={callbackAfterLogin} callbackAfterCancel={callbackAfterCancel}/>
        {addresses.length>0?<AddressSelectDialog title={"Wybierz adres"} items={addresses} onSelect={onAddressSelect} />:null}
        <AlertDialog alert={alert} setAlert={setAlert} />
        <StoreRegulationDialog open={ openStoreRegulation } setOpen={setOpenStoreRegulation} content={regulationContent}  />
            <Container maxWidth="lg">
                <Title>
                    {store_data.name}
                </Title>
                <SubText >
                    {store_data.address_information.street + " " + store_data.address_information.building_number}
                </SubText>
                {selectedDeliveryType==="collect"?<div>
                    {store_data.collect_waiting_time&&store_data.collect_waiting_time!==""?<SubText >
                        Odbiór za: {store_data.collect_waiting_time} min
                    </SubText>:null}
                </div>:
                <div>
                    {store_data.delivery_waiting_time&&store_data.delivery_waiting_time!==""?<SubText >
                        Dostawa za: {store_data.delivery_waiting_time} min
                    </SubText>:null}
                </div> }

            </Container>
            <Box  >
                <Container maxWidth="lg">
                    <Grid container spacing={6} >
                        <Grid item xs={12} sm={6} >
                            <SubTitle textAlign="center">
                                TWOJE INFORMACJE
                            </SubTitle>
                            <CheckoutSelectRow title={selectedDeliveryTypeName} icon={selectedDeliveryType==="delivery"?"bike":"collect"} items={availableDeliveryTypes} setValue={updateDeliveryType} />
                            {availableCompletionTypes.length>1?<CheckoutSelectRow title={selectedCompletionName} icon={"clock"} items={availableCompletionTypes} setValue={updateComplationType} />:null}
                            {selectedCompletionType==="schedule"?<CheckoutSelectRow title={selectDateName} icon={"calendar"} items={availableCompletionDates} setValue={updateComplationDate} />:null}
                            {showSelectTime?<CheckoutSelectRow title={selectTimeName} icon={"clock"} items={availableCompletionTimes} setValue={updateComplationTime} />:null}
                            <div style={{paddingTop:"16px",paddingBottom:"16px"}}/>
                            {selectedDeliveryType==="delivery"?<CheckoutDeliveryNotesRow title={deliveryNote!==""?deliveryNote:"podaj kod do domofonu, wskazówki dostawy"} paceholder={"notatka"} deliveryNote={deliveryNote} popupTitle={"Wskazówka dla kuriera"} icon={"bell"} onChange={updateDeliveryNote} />:null}
                            {selectedDeliveryType==="delivery"?<CheckoutAddressRow title={"Adres: " + addressLabel } highlight={addressLabel===""?true:false} popupTitle={"Adres dostawy"} icon={"location"} onChange={updateDeliveryAddress} onClose={onAddressClose} showEditIcon={true} openDialog={openAddressDialog} setOpenDialog={setOpenAddressDialog} onOpen={onOpenAddressDialog} />:null}
                            {selectedDeliveryType==="collect"&&requireUserName===true?<CheckoutCustomerName title={"Imię: " + userName } customeName={userName} popupTitle={"Imię i nazwisko"} icon={"user"} onChange={updateUserName} showEditIcon={true} onClose={onUserNameClose} />:null}
                            {requirePhoneNumber===true?<CheckoutPhoneNumberRow title={"Tel. " + phoneNumber} phoneNumber={phoneNumber} popupTitle={"Numer telefonu"} icon={"phone"} onChange={updatePhonenumber} onClose={onPhonenumberClose} />:null}
                            <CheckoutInfoRow title={"Płatność: " + paymentMethodName} icon={"payment"}  />
                            <CheckoutButtonRow title={"Dodaj kod promocyjny"} value={newPromoCode} popupTitle={"Kod"} icon={"promocode"} buttonIcon={"plus"} onChange={updatePromocode} onClose={onAddPromocodeClose} warrningText={promocodeWarrningMessage} successText={promocodeSuccessMessage} />
                            {availablePromocodes.length>1?<CheckoutSelectRow title={selectedPromocodeName} icon={"promocode"} items={availablePromocodes} setValue={updateSelectedPromocode} />:null}
                            <SwitchRow>
                                <Text onClick={()=>{getStoreRegulation(setShowLoader, setOpenStoreRegulation, setRegulationContent)}}>Akceptuje regulamin</Text>
                                <Switch
                                    checked={true}
                                    onChange={()=>{ alert("Akceptacja regulaminu jest wymagana aby dokonać zamówienia. Nie zamawiaj jeśli nie akceptujesz regulaminu.") }}
                                    name="checkedA"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}

                                  />
                            </SwitchRow>

                            <SwitchRow>
                                <Text>Chcesz otrzymać fakturę?</Text>
                                <Switch
                                    checked={invoiceRequired}
                                    onChange={()=>{ setInvoiceRequired(!invoiceRequired) }}
                                    name="checkedA"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                            </SwitchRow>
                            {invoiceRequired?<CheckoutDeliveryNotesRow title={"Twoje dane firmowe: " + businessInformation} deliveryNote={businessInformation} paceholder={"nazwa, adres, NIP itp."} popupTitle={"dane firmowe"} icon={"business"} onChange={updateBusinessInfo} />:null}
                            
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {basketHook.basket.length === 0 ? <BasketContainer>nic nie masz w koszyku</BasketContainer>:( 
                                <BasketContainer> 
                                    {" "}
                                        <SubTitle>
                                            TWOJE ZAMÓWIENIE
                                        </SubTitle>
                                    {" "}
                 
                                    {basketHook.basket.map(product => (
                                        product.show_item?<ProductsContainer key={product.uid}>
                                            {!product.promo_item?<ProductItem>
                                                <SubText>{product.quantity}x</SubText>
                                                <Text>{product.name}</Text>
                                                <ProductPrice style={{margin:"0px"}}>{product.price} zł</ProductPrice>
                                                <DeleteIcon color={"primary"} style={{marginLeft:"0px"}} onClick={()=>{deleteItem(product)} }/>
                                            </ProductItem>:<ProductItem>
                                                <div></div>
                                                <Text>{product.name}</Text>
                                            </ProductItem>}
                                                {product.selected_options?product.selected_options.map(option => (
                                                    <SubText key={option.uid}>{option.name} +{option.price} zł</SubText>
                                                )):null}
                                        </ProductsContainer>:null
                                    ))}

                                    <CheckoutNoteRow rowLabel={orderNote} buttonLabel={"dodaj notatkę"} popupTitle={"notatka do zamówienia"} onChange={updateOrderNote}  />
                                      
                                    <SummaryContainer> 
                                        <InfoItem>
                                            <div/>
                                            <Text>zamówienie: {totalProductsCost} zł</Text>
                                            <div></div>
                                            <div/>
                                        </InfoItem>
                                        {promoDiscountValue>0?<InfoItem>
                                            <div/>
                                            <Text>promocja: -{promoDiscountValue} zł</Text>
                                            <div></div>
                                            <div/>
                                        </InfoItem>:null}
                                        {promocodeDiscountValue>0?<InfoItem>
                                            <div/>
                                            <Text>kod promocyjny: -{promocodeDiscountValue} zł</Text>
                                            <div></div>
                                            <div/>
                                        </InfoItem>:null}
                                        <InfoItem>
                                            <div/>
                                            <Text>koszt dostawy: {deliveryPrice} zł</Text>
                                            <div></div>
                                            <div/>
                                        </InfoItem>
                                        <InfoItem>
                                            <div/>
                                            <Text>SUMA: {Math.round( (totalProductsCost + deliveryPrice - promoDiscountValue - promocodeDiscountValue) *100)/100} zł</Text>
                                            <div></div>
                                            <div/>
                                        </InfoItem>
                                        {remainingMinimumOrderValue>0&&selectedDeliveryType==="delivery"?<Alert style={{marginTop:"16px"}} severity="error">Kwota minimalna nie została osiągnięta dla zamówienia z dostawą. <br/> Brakuje <b>{remainingMinimumOrderValue} zł</b></Alert>:null}
                                        {deliveryPrice>0&&freeDeliveryFrom>0&&freeDeliveryFrom - (totalProductsCost - promoDiscountValue - promocodeDiscountValue) > 0&&selectedDeliveryType==="delivery"?<Alert style={{marginTop:"16px"}} severity="info">Do <b>darmowej dostawy</b> brakuje <b>{ roundPrice(freeDeliveryFrom - (totalProductsCost - promoDiscountValue - promocodeDiscountValue) ) } zł</b></Alert>:null}
                                    </SummaryContainer> 
                                </BasketContainer>
                        )}
                        </Grid>
                    </Grid>
                    <Box textAlign="center" pt={{xs: 5, sm: 10}} pb={{xs: 5, sm: 0}} >
                       {canOrder==true?<Fab variant="extended" color="primary" style={{zIndex: "99", width: "220px"}} onClick={()=>checkIfCanOrder()} >
                            ZAMÓW | { Math.round( (totalProductsCost + deliveryPrice - promoDiscountValue - promocodeDiscountValue) *100)/100 } zł
                        </Fab>:null}
                    </Box>
                </Container>
            </Box>
        </>:null}
    </>
  )
}

