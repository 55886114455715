import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import CakeIcon from '@material-ui/icons/Cake';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PublicIcon from '@material-ui/icons/Public';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import StarIcon from '@material-ui/icons/Star';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export function NewBreadcrumbs({section})
{
  const classes = useStyles();

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  return <Breadcrumbs aria-label="breadcrumb" style={{marginTop:"16px"}}>
      <Link color="inherit" href="/start" className={classes.link}>
        <HomeIcon className={classes.icon} />
        Start
      </Link>
      {section==="restaurant"?<Typography color="textPrimary" className={classes.link}>
        <FastfoodIcon className={classes.icon} />
        restauracje
      </Typography>:null}

      {section==="catering"?<Typography color="textPrimary" className={classes.link}>
        <RoomServiceIcon className={classes.icon} />
        Catering
      </Typography>:null}

      {section==="cake"?<Typography color="textPrimary" className={classes.link}>
        <CakeIcon className={classes.icon} />
        Torty
      </Typography>:null}

      {section==="foodtruck"?<Typography color="textPrimary" className={classes.link}>
        <LocalShippingIcon className={classes.icon} />
        Food truck
      </Typography>:null}

      {section==="mtp"?<Typography color="textPrimary" className={classes.link}>
        <PublicIcon className={classes.icon} />
        MTP
      </Typography>:null}

      {section==="breakfast"?<Typography color="textPrimary" className={classes.link}>
        <LocalDiningIcon className={classes.icon} />
        Śniadania
      </Typography>:null}

      {section==="special"?<Typography color="textPrimary" className={classes.link}>
        <StarIcon className={classes.icon} />
        Okazjonalne
      </Typography>:null}

      {section==="delicatese"?<Typography color="textPrimary" className={classes.link}>
        <LocalGroceryStoreIcon className={classes.icon} />
        Delikatesy
      </Typography>:null}

      {section==="drink"?<Typography color="textPrimary" className={classes.link}>
        <LocalDrinkIcon className={classes.icon} />
        Napoje
      </Typography>:null}

      
    </Breadcrumbs>
}