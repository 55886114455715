import styled from "styled-components"
import { color_primary } from "../styles/globalstyle"
import { useState, useEffect} from "react";
import { loginUser, isLoggedIn, registerUser, resetUserPassword } from "../services/user"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PoweredByZio } from "../components/powered_by_zio"


const Dialog = styled.div`
	width: 360px;
	min-height: 280px;
	background-color: white;
	position: fixed;
	top: 75px;
	z-index:1001;
	max-height: calc(100% - 100px);
	left: calc(50% - 180px);
	border-radius:6px;
	display: flex;
	flex-direction: column;
`

export const DialogContent = styled.div`
	padding: 16px;
`

const Name = styled.div`
	font-family: 'Quicksand', sans-serif;
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 8px;
`

export const DialogFooter = styled.div`
	height: 44px;
	padding-left: 16px;
	padding-right: 16px;
	border-radius:6px;
	margin-top: 8px;
	display: flex;
	justify-content: center;
`


const DialogShadow = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0px;
	background-color: black;
	opacity: 0.3;
	z-index: 1000;
`


const Text = styled.div`
    font-size: 16px;
    padding: 16px;
`
const Link = styled.a`
    font-size: 16px;
    color: ${color_primary};
    &:hover {
		cursor: pointer;
	}
`

const TextWarrning = styled.div`
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: red;
`

const TextInformation = styled.div`
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: blue;
`

export function LoginDialog({callbackAfterLogin, callbackIfLoggedin, callbackAfterCancel})
{
	const [defaultZioUi, setDefaultZioUi] = useState(localStorage.getItem("custom_store_object_id")===null||localStorage.getItem("custom_store_object_id")===""?true:false)
	const [openLoginDialog, setOpenLoginDialog] = useState(false);
	const [username, setUsername] = useState("");
  	const [password, setPassword] = useState("");
  	const [isLogin, setIsLogin] = useState(true);
  	const [resetPasswordUi, setResetPasswordUi] = useState(false);
  	const [agreement, setAgreement] = useState(false);
  	const [wrongCredentials, setWrongCredentials] = useState("");
  	
  	useEffect(() => {
	    if (isLoggedIn() === false)
		{
			setOpenLoginDialog(true)
		}
		else 
		{
			if (callbackIfLoggedin) { callbackIfLoggedin() }
		}
	}, [])
	

	function close()
	{
		setOpenLoginDialog(false)
	}

	async function onSubmit()
	{
		if (isLogin)
		{
	  		const result = await loginUser(username, password, callbackAfterLogin)
	  		if (result === "ok") 
	  		{
	  			close()
	  		}
	  		else 
	  		{
	  			if (defaultZioUi)
	  			{
  					setWrongCredentials("błędny login lub hasło")
	  			}
	  			else 
	  			{
	  				setWrongCredentials("błędny login lub hasło. Możesz użyć login i hasło z ZiO food. ")
	  			}
	  		}
		}
		else  
		{
			const result = await registerUser(username, password)
			if (result === "ok") 
	  		{
	  			await loginUser(username, password, callbackAfterLogin)
	  			close()
	  		}
	  		else 
	  		{
	  			if (defaultZioUi)
	  			{
	  				setWrongCredentials("Błąd rejestracji. Błędny email lub konto już istnieje.")
	  			}
	  			else 
	  			{
	  				setWrongCredentials("Błąd rejestracji. Błędny email lub konto już istnieje. Spróbuj uzyć login z ZiO food.")
	  			}
	  		}
		}
	}

	async function onResetPasswordSubmit(event)
	{
		if (username !== "" && username.search("@") !== -1)
		{
			await resetUserPassword(username)
	  		setWrongCredentials("Link do odzyskania hasła został wysłany na " + username + ". Sprawdź pocztę.")
		}
		else  
		{
			setWrongCredentials("Podaj prawidłowy adres email.")
		}
	}
	
	function toggleResetPassword()
	{
		setWrongCredentials("")
		setResetPasswordUi(!resetPasswordUi)
	}

	function toggle()
	{
		setWrongCredentials("")
		setIsLogin(!isLogin)
	}	

	function agreementToggle()
	{
		setAgreement(!agreement)
	}

	function agreementToggle()
	{
		setAgreement(!agreement)
	}

	function handleSubmit(event) 
	{
	    event.preventDefault();
	}

	

	function onCancel()
	{
		close()
		callbackAfterCancel()
	}

	// if (!openLoginDialog) { return null}
	return openLoginDialog?<>
		<DialogShadow onClick={onCancel} />
		{resetPasswordUi?<Dialog>
			<DialogContent>
				<Name>
					Odzyskaj hasło
				</Name>
				{wrongCredentials!==""?<TextInformation>
					{wrongCredentials}
				</TextInformation>:null}
				<form onSubmit={handleSubmit} >
				<TextField 
					fullWidth={true}
					autoFocus
		            margin="dense"
		            id="email"
		            label="twój adres email"
		            type="text"
					value={username}
					onChange={(e) => setUsername(e.target.value)} 
				/>
				</form>
			</DialogContent>
			<DialogFooter>
				<Button variant="contained" color="primary" fullWidth="true" size="medium" onClick={onResetPasswordSubmit} >
					ODZYSKAJ
				</Button  >
			</DialogFooter>
			<DialogContent>
				wróć do <Link onClick={toggleResetPassword}>logowania</Link>
			</DialogContent>
			{defaultZioUi?null:<DialogContent>
				<PoweredByZio/>
			</DialogContent>}
		</Dialog>:<>
			{isLogin?<Dialog>
				<DialogContent>
					<Name>
						Zaloguj się aby zamówić
					</Name>
					{wrongCredentials!==""?<TextWarrning>
						{wrongCredentials}
					</TextWarrning>:null}
					<form onSubmit={handleSubmit} >
					<TextField 
						fullWidth={true}
						autoFocus
			            margin="dense"
			            id="email"
			            label="email"
			            type="text"
						value={username}
						onChange={(e) => setUsername(e.target.value)} 
					/>
					<TextField 
						fullWidth={true}
			            margin="dense"
			            id="password"
			            label="hasło"
			            type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)} 
					/>

					</form>
				</DialogContent>
				<DialogFooter>
					<Button variant="contained" color="primary" fullWidth="true" size="medium" onClick={onSubmit} >
						ZALOGUJ
					</Button  >
				</DialogFooter>
				<DialogContent>
					nie masz konta w ZiO food? <Link onClick={toggle}>zarejestruj się</Link>
				</DialogContent>
				<DialogContent style = {{paddingTop:"0px", paddingBottom:"8px"}}>
					nie pamiętasz hasła? <Link onClick={toggleResetPassword}>odzyskaj</Link>
				</DialogContent>
				{defaultZioUi?null:<DialogContent>
					<PoweredByZio/>
				</DialogContent>}

			</Dialog>:<Dialog>
				<DialogContent>
					<Name>
						Rejestracja
					</Name>
					{wrongCredentials!==""?<TextWarrning>
						{wrongCredentials}
					</TextWarrning>:null}
					<form onSubmit={handleSubmit} >
						<TextField 
							fullWidth="true"
							autoFocus
				            margin="dense"
				            id="email"
				            label="email"
				            type="text"
							value={username}
							onChange={(e) => setUsername(e.target.value)} 
						/>
						<TextField 
							fullWidth="true"
				            margin="dense"
				            id="password"
				            label="hasło"
				            type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)} 
						/>
					</form>
					<FormControlLabel
				        control={
				          <Checkbox
				            checked={agreement}
				            onChange={agreementToggle}
				            name="checkedB"
				            color="primary"
				          />
				        }
				        label="akceptuję regulamin i politykę prywatności."
				      />
				</DialogContent>
				<DialogFooter>
					<Button variant="contained" color="primary" disabled={!agreement} fullWidth="true" size="medium" onClick={onSubmit} >
						ZAREJESTRUJ
					</Button>
				</DialogFooter>
				<DialogContent>
					masz konto w ZiO food? <Link onClick={toggle}>zaloguj się</Link>
				</DialogContent>
				{defaultZioUi?null:<DialogContent>
					<PoweredByZio/>
				</DialogContent>}
			</Dialog>}
		</>}
	</>:null
}