const stores = [
    {"domain":"localhost","store_object_id": "Ci7GGvDFBZ", "color_primary": "#74BF00" ,"color_accent": "#000000"},//, "user_object_id":"ZNDijRZ15m" "eyGZHLKspC"},57pfzB7e7g  "user_object_id":"ZNDijRZ15m"

    {"domain":"sztospizza","store_object_id": "57pfzB7e7g", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"vegepizza","store_object_id": "UklkxA5IAC", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"tajskibox","store_object_id": "9ESOnealBu", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"zasciankowejadlo","store_object_id": "55su1o3ydS", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"tashimomo","store_object_id": "qn2q2CpmMw", "color_primary": "#DE4042" ,"color_accent": "#000000"},
    {"domain":"lapapaya","store_object_id": "daIIqTf5qr", "color_primary": "#F08664" ,"color_accent": "#000000"},

    {"domain":"hummusownia","store_object_id": "Ffk7NGNDvg", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"lunacovrigaria","store_object_id": "3sN032XJKU", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"swietakrowa","store_object_id": "SRMcdh5WKz", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"geraniumvege","store_object_id": "mawOdTlwYa", "color_primary": "#316900" ,"color_accent": "#000000"},  

    {"domain":"wloskiwinkiel","store_object_id": "qGsDNfer1r", "color_primary": "#019A30" ,"color_accent": "#000000"},
    {"domain":"przekazdalej","store_object_id": "j5KkkwcM3n", "color_primary": "#5CA494" ,"color_accent": "#000000"},    

    {"domain":"chatapolska","store_object_id": "oNjEjb7a6j", "color_primary": "#0CA94E" ,"color_accent": "#000000"},   
    {"domain":"sevikebabavenida","store_object_id": "aWxkzpCTcm", "color_primary": "#0CA94E" ,"color_accent": "#000000"}, 

    {"domain":"warzywniak","store_object_id": "6tYCHLWYD3", "color_primary": "#000000" ,"color_accent": "#000000"}, 
    {"domain":"nanopizzas","store_object_id": "h5DgZ29UxR", "color_primary": "#000000" ,"color_accent": "#000000"},  

    {"domain":"pokebo","store_object_id": "m1aEF8yqV2", "color_primary": "#000000" ,"color_accent": "#000000"},  
    {"domain":"bronxjezyce","store_object_id": "tjyyrCWGGm", "color_primary": "#000000" ,"color_accent": "#000000"}, 
    {"domain":"betterhurry","store_object_id": "BqBI3X8dyA", "color_primary": "#000000" ,"color_accent": "#000000", "user_object_id":"wms3igyJ2W"},   
    {"domain":"kotletnalibelta","store_object_id": "taW7Sr5M1p", "color_primary": "#000000" ,"color_accent": "#000000"},   
    {"domain":"umamigotowanie","store_object_id": "UeDP3cgKh3", "color_primary": "#81D742" ,"color_accent": "#000000"},   

    {"domain":"cukierniaweber","store_object_id": "lOWPYa493d", "color_primary": "#000000" ,"color_accent": "#000000", "user_object_id":"NS52lI40Ow"},
    {"domain":"sklepjezyk","store_object_id": "lh0OGb8f6z", "color_primary": "#E67817" ,"color_accent": "#0264E3"}, 
    {"domain":"wytworniasmaq","store_object_id": "wHyOEtNY9X", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"gongpoznan","store_object_id": "TOreXBW6Rr", "color_primary": "#000000" ,"color_accent": "#000000"},
    {"domain":"savetheplanet","store_object_id": "4tW0jjFdQW", "color_primary": "#000000" ,"color_accent": "#000000"},

    {"domain":"lapapayafiesta","store_object_id": "HTT2AOQFmg", "color_primary": "#F08664" ,"color_accent": "#000000"},    
    {"domain":"ciuciupaj","store_object_id": "Z5yGYU51Ah", "color_primary": "#A7D6DE" ,"color_accent": "#000000"},    
    {"domain":"granvegano","store_object_id": "qWTWsLUqhD", "color_primary": "#000000" ,"color_accent": "#000000"},
]

export default stores