import styled from "styled-components"
import {color_primary, color_accent} from "../styles/globalstyle"
import {ProductModifier} from "../components/product_modifiers"
import {ProductOption} from "../components/product_options"
import {getAllergens} from "../data/allergen"
import { useState, useEffect} from "react";
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import { v4 as uuidv4 } from 'uuid';


const DialogContent = styled.div`
	overflow: auto;
	min-height: 100px;
	padding-bottom: 32px;
`

export const DialogTextContent = styled.div`
	margin-left: 16px;
`

const DialogFooter = styled.div`
	box-shadow: 0px 2px 10px 0px gray;
	height: auto;
	border-radius:6px;
	display: flex;
	justify-content: center;
`

const ConfirmButton = styled.div`
	margin: 10px;
	color: white;
	height: 22px;
	border-radius: 5px;
	padding: 10px;
	text-align: center;
	min-width: 200px;
	cursor: pointer;
	background-color: ${color_primary}; 
	${({ disabled }) =>
		disabled && `opacity: 0.5;
		pointer-events:none;
	`}
`

const DialogShadow = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0px;
	background-color: black;
	opacity: 0.3;
	z-index: 4;
`

const NameGrid = styled.div`
	display: grid;
  	grid-template-columns: auto 90px;
  	padding-top: 24px;
    padding-bottom: 16px;
`

const ProductName = styled.h1`
    font-size: 28px;
    margin: 0;
    font-weight: 700;
`

const Description = styled.div`
    font-size: 18px;
    font-weight: 400;
    padding-right:8px;
	color: gray;
`

export const ProductPrice = styled.h4`
	font-size: 28px;
    margin: 0;
	color: ${color_accent};
	text-align: left;
	// margin-left: 10px;
`

const Stock = styled.div`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
	color: ${color_primary};
	padding-bottom: 12px;
`

const DialogBanner = styled.div`
	height: 460px;
	${({image}) => (image ? `background-image: url(${image})` : `min-height: 75px` )};
	background-position: center;
	background-size: cover;
`

const ConeterItemContainer = styled.div`
	height: auto;
	display: flex;
	justify-content: center;
`

const SmallCenterText = styled.div`
	font-size: 13px;
	padding: 16px;
	text-align: center
`

const CloseButton = styled.div`
	position: absolute; 
	right:16px;
	top: 16px;
	border-radius: 16px;
	width: 32px;
	height: 32px;
	background-color: white;
	cursor: pointer;
`

const iconStyleGreen = {
	fontSize: 34,
	color: "green",
};

function hasModifiers(product)
{
	return product.modifiers && product.modifiers.length > 0;
}


function getSelectedOptionsCount(items, group_id)
{
	let count = 0
	for (let i = 0; i < items.length; i++ )
	{
		if (items[i].parent_id === group_id)
		{
			count++		
		} 
	}
	return count
}

function isRuleFulfilled(optionGroups, setOptionGroups, optionsSelected) // make sure all rules are correct. minimum and maximum options.
{
	let _option_groups = [...optionGroups]
	let _required_options_selected = true
	for (let i = 0; i < _option_groups.length; i++)
	{
		if (_option_groups[i].rule !== null)
		{
			_option_groups[i].selected_count = getSelectedOptionsCount(optionsSelected, _option_groups[i].id)
		}
	}

	let _all_rules_are_fulfilled = true
	for (let i = 0; i < _option_groups.length; i++)
	{
		if (_option_groups[i].rule && _option_groups[i].selected_count < _option_groups[i].rule.minimum)
		{
			_all_rules_are_fulfilled = false
			// break
		}
	}
	setOptionGroups(_option_groups)
	return _all_rules_are_fulfilled
} 
	

function ProductDialogContainer({product, setProduct, setOpenProduct, basket, setBasket, is_open, addProduct })
{
	const [optionsPrice, setOptionsPrice] = useState(0)
	const [rulesAreFulfilled, setRulesAreFulfilled] = useState(true)
	const [optionGroups, setOptionGroups] = useState([])

	const [optionsSelected, setOptionsSelected] = useState([])
	const [modifierSelected, setModifierSelected] = useState(0)
	const [price, setPrice] = useState(product.modifiers&&product.modifiers[0]?product.modifiers[0].price:product.price)
	const [name, setName] = useState(product.modifiers&&product.modifiers[0]?product.name + " | " + product.modifiers[0].name:product.name )
	const [stock, setStock] = useState(0)
	const [spiceLevelName, setSpiceLevelName] = useState("")

	function close() 
	{
		setProduct()
	}

	let _product = {
		name: name,
		id: product.id,
		uid: uuidv4(),
		stock: product.stock,
		description: product.description,
		price: price,
		quantity: product.quantity,
		disabled: product.disabled,
		modifiers: product.modifiers,
		modifier: modifierSelected,
		options: product.options,
		selected_options: optionsSelected,
		category_object_id: product.category_object_id,
		diets: product.diets,
		show_item: true,
		add_new:true,
	}

	function addToBasket()
	{
		// product.quantity = product.quantity + 1
		// _product.quantity = 1
		// setBasket([...basket, _product])
		addProduct(_product)
		close()
	}

	function addOption(e, option)
	{ 
    	let _optionsSelected = [...optionsSelected]

    	let _option_groups = [...optionGroups]
    	let _add_it = true

    	if (option.required)
    	{
    		for (let i = 0; i < _optionsSelected.length; i++) 
	    	{
	    		if (_optionsSelected[i].parent_id === option.parent_id)
		  		{
		  			_optionsSelected.splice(i, 1)
		  		}
	    	}
	    	for (let i = 0; i < _option_groups.length; i++)
	    	{
	    		if (_option_groups[i].id === option.parent_id)
	    		{
	    			for (let j = 0; j < _option_groups[i].items.length; j++) // set check flat to set checboxes properly
	    			{
	    				if (_option_groups[i].items[j].uid == option.uid)
	    				{
	    					_option_groups[i].items[j].checked = true
	    				}
	    				else  
	    				{
	    					_option_groups[i].items[j].checked = false
	    				}
	    			}
	    		}
	    	}
    	}
    	else  
    	{
	    	for (let i = 0; i < _optionsSelected.length; i++) 
	    	{
	    		if (_optionsSelected[i].uid === option.uid)
		  		{
		  			_optionsSelected.splice(i, 1)
		  			_add_it = false
		  		}
	    	}
	    	for (let i = 0; i < _option_groups.length; i++)
	    	{
	    		if (_option_groups[i].id === option.parent_id)
	    		{
	    			if (_option_groups[i].selected_count >= _option_groups[i].rule.maximum) // block adding if max limit is reached
	    			{
	    				_add_it = false
	    			}
	    			
	    			for (let j = 0; j < _option_groups[i].items.length; j++) // set check flat to set checboxes properly
	    			{
	    				if (_option_groups[i].items[j].uid == option.uid && (_add_it || _option_groups[i].items[j].checked) )
	    				{
	    					_option_groups[i].items[j].checked = !_option_groups[i].items[j].checked
	    				}
	    			}
	    		}
	    	}
    	}

    	setOptionGroups(_option_groups)


    	if (_add_it)
    	{
    		_optionsSelected.push(option)
    	}

    	setOptionsSelected(_optionsSelected)

    	let _total_price = 0
    	for (let i = 0; i < _optionsSelected.length; i++) 
    	{
    		_total_price = _total_price + _optionsSelected[i].price
    	}
		setOptionsPrice(_total_price )
		setRulesAreFulfilled(isRuleFulfilled(_option_groups, setOptionGroups, _optionsSelected))
	}

	function updateOptionsOnModifierChange(option_groups, modifier_selected, options_selected)
	{
		if (product.modifiers && product.modifiers[modifier_selected] && product.modifiers[modifier_selected].tag)
		{
			// update whole list
			let _option_groups = [...option_groups]
			for (let i = 0; i < _option_groups.length; i++) 
	    	{
	    		for (let j = 0; j < _option_groups[i].items.length; j++) 
		    	{
		    		if (_option_groups[i].items[j].modifiers)
		    		{
		    			for (let k = 0; k < _option_groups[i].items[j].modifiers.length; k++) 
		    			{
							if (_option_groups[i].items[j].modifiers[k].tag && product.modifiers[modifier_selected].tag === _option_groups[i].items[j].modifiers[k].tag)
							{								
								_option_groups[i].items[j].price = _option_groups[i].items[j].modifiers[k].price
								_option_groups[i].items[j].modifier_tag = _option_groups[i].items[j].modifiers[k].tag // set tag to use in cloud code
							} 
							
		    			}
		    		}				
		    	}
	    	}
	    	setOptionGroups(_option_groups)

	    	// update selected for price calculation
	    	let _options_selected = [...options_selected]
	    	for (let i = 0; i < _options_selected.length; i++) 
	    	{
	    		if (_options_selected[i].modifiers)
	    		{
	    			for (let k = 0; k < _options_selected[i].modifiers.length; k++) 
	    			{
						if (_options_selected[i].modifiers[k].tag && product.modifiers[modifier_selected].tag === _options_selected[i].modifiers[k].tag)
						{						
							_options_selected[i].price = _options_selected[i].modifiers[k].price
							_options_selected[i].modifier_tag = _options_selected[i].modifiers[k].tag // set tag to use in cloud code
						} 
	    			}
	    		}
	    	}
	    	// calculate new options price
	    	let _total_price = 0
	    	for (let i = 0; i < _options_selected.length; i++) 
	    	{
	    		_total_price = _total_price + _options_selected[i].price
	    	}
	    	setOptionsSelected(_options_selected)
			setOptionsPrice(_total_price )
		}
	}

	function selectModifier(modifier, index)
	{
		setPrice(modifier.price)
		setModifierSelected(index)
		setName(product.name + " | " + modifier.name)
		updateOptionsOnModifierChange(optionGroups, index, optionsSelected)
	}

	
	useEffect(() => { // set initial state
  		if (optionGroups.length == 0) // get all options and set initial state for fulfilment
  		{
  			let _option_groups = []
	  		for (let i = 0; i < product.options.length; i++)
	  		{
	  			let _item = {
	  				id: product.options[i].id,
	  				name: product.options[i].name,
	  				rule: product.options[i].rule,
	  				selected_count: 0,
	  				items: []
	  			}
	  			let _checked = false
	  			for (let j = 0; j < product.options[i].items.length; j++)
	  			{
	  				if (j === 0 && product.options[i].rule && product.options[i].rule.minimum === 1 && product.options[i].rule.maximum === 1) // this is for options which ir require and has 
	  				{
	  					_checked = true
	  					_item.selected_count = 1
	  				}
	  				else  
	  				{
	  					_checked = false
	  				}
	  				_item.items.push({
	  					checked: _checked,
	  					id: product.options[i].items[j].id,
	  					modifiers: product.options[i].items[j].modifiers,
	  					name: product.options[i].items[j].name,
	  					parent_id: product.options[i].items[j].parent_id,
	  					price: product.options[i].items[j].price,
	  					uid: product.options[i].items[j].uid,
	  					required: (product.options[i].rule && product.options[i].rule.minimum === 1 && product.options[i].rule.maximum === 1)?true:false
	  				})
	  			}
	  			_option_groups.push(_item)
	  		}
	  		_option_groups.sort(function(a, b) { return b.rule.minimum - a.rule.minimum })
	   		setOptionGroups(_option_groups)
	   		let _optionsSelected = []
	   		for (let i = 0; i < _option_groups.length; i++)
	  		{
	  			for (let j = 0; j < _option_groups[i].items.length; j++)
	  			{
	  				if (_option_groups[i].items[j].checked)
	  				{
	  					_optionsSelected.push(_option_groups[i].items[j])
	  				}
	  			}
	  		}
	  		let _total_price = 0
	    	for (let i = 0; i < _optionsSelected.length; i++) 
	    	{
	    		_total_price = _total_price + _optionsSelected[i].price
	    	}
			setOptionsPrice(_total_price )
	  		setOptionsSelected(_optionsSelected)
	   		setRulesAreFulfilled(isRuleFulfilled(_option_groups, setOptionGroups, _optionsSelected))
	   		updateOptionsOnModifierChange(_option_groups, 0, _optionsSelected)

	   		let _stock_count = 0
	   		for (let i = 0; i < basket.length; i++) // find the same product in basket to cound the stock
	  		{
	  			if (basket[i].id === product.id)
	  			{
	  				_stock_count = _stock_count + basket[i].quantity
	  				
	  			}
	  		}
	  		if (product.stock - _stock_count >= 0) // update stock
	  		{
	  			setStock(product.stock - _stock_count) //  set stock if there are objects in 
	  		}
	  		else
	  		{
	  			setStock(-1)
	  		}
  		}

  		if (product.spice_level&&product.spice_level>0)
  		{
  			if (product.spice_level === 1)
  			{
  				setSpiceLevelName("lekko ostre")
  			}
  			else if (product.spice_level === 2)
  			{
  				setSpiceLevelName("ostre")
  			}
  			else if (product.spice_level === 3)
  			{
  				setSpiceLevelName("bardzo ostre")
  			}	
  		}
  	}, [])

  	if (!product) return null;

	return product ? <>
		<DialogShadow onClick={close} />
		<Dialog open={true}
        onClose={close}
        scroll={"paper"}
        fullWidth={true}
        >
			<DialogContent>
				<CloseButton onClick={close}>
					<CloseIcon style={{display: "block", marginLeft: "auto", marginRight: "auto", marginTop:"4px", color:color_primary}}/>					
				</CloseButton>
				{product.large_image_url?<DialogBanner image={product.large_image_url} />:null}
				<DialogTextContent>
				<NameGrid>
					<ProductName item>
						{product.name}
					</ProductName>
					{price>0?<ProductPrice>
						{price} zł
					</ProductPrice>:null}
				</NameGrid>

				<Description>
					{product.description}
				</Description>

				{product.weight&&product.weight>0?
				<Description style={{marginTop: "6px"}}>
					<b>waga około: </b> {product.weight}kg
				</Description>:null}

				<ConeterItemContainer>
					{product.markings&&product.markings.cutlery_required?
						<Tooltip title="Wymaga sztućcy">
							<RestaurantIcon style={{color: "#CDCDCD", fontSize: "32px", marginTop:"12px", marginLeft: "8px"}} />
						</Tooltip>:null}

					{product.markings&&product.markings.heating_required?
						<Tooltip title="Wymaga podgrzania">
							<FireplaceIcon style={{color: "#CDCDCD", fontSize: "32px", marginTop:"12px", marginLeft: "8px"}} />
						</Tooltip>:null}

					{product.diets&&product.diets.glutenfree?
						<Tooltip title="Gluten free">
							<img src="../images/diet_glutenfree_icon_64.png" alt="glutenfree icon" width="32" height="32" style={{marginTop: "12px", paddingLeft: "8px", paddingRight: "8px" }} />
						</Tooltip>:null}

					{product.diets&&product.diets.soyafree?
						<Tooltip title="Soya free">
							<img src="../images/diet_soyafree_icon_64.png" alt="soyafree icon" width="32" height="32" style={{marginTop: "12px", paddingLeft: "8px", paddingRight: "8px" }} />
						</Tooltip>:null}
				
	                {product.diets&&product.diets.vegetarian?
	                	<Tooltip title="Wegetariańskie">
	                		<img src="../images/diet_vegetarian_icon.png" alt="vegetarian icon" width="32" height="32" style={{marginTop: "12px", paddingLeft: "8px", paddingRight: "8px" }} />
	                	</Tooltip>:null}
	                {product.diets&&product.diets.vegan?
	                	<Tooltip title="Wegańskie">
	                		<img src="../images/diet_vegan_icon.png" alt="vegan icon" width="32" height="32" style={{marginTop: "12px", paddingLeft: "8px", paddingRight: "8px"}} />
	                	</Tooltip>:null}
					
					{product.spice_level&&product.spice_level>0?[...Array(product.spice_level)].map((x, i) =>
						<Tooltip title={spiceLevelName}>
							<img src="../images/spice_level_icon.png" alt="spice level icon" width="24" height="32" style={{marginTop: "12px", paddingLeft: "0px", paddingRight: "0px" }} />
						</Tooltip>):null}
				</ConeterItemContainer>

				{price>0?<SmallCenterText>{getAllergens(product.allergen)}</SmallCenterText>:null}

				{hasModifiers(product) && <>
					<h4>Rodzaj</h4>
					{product.modifiers.map((modifier, i) => (
						<ProductModifier modifier={modifier} index={i} modifierSelected={modifierSelected} selectModifier={selectModifier} key={modifier.name} />
					))}
				</>}

				{optionGroups.length > 0 && <>
					{optionGroups.map(option_category => (
						<div key={option_category.id}>
							<h4 style={{fontWeight:"800" }}>{option_category.name}</h4>
							{option_category.items.map(option => (
								<ProductOption option={option} key={option.id} addOption={addOption} />
							))}
						</div>
					))}
				</>}

				</DialogTextContent>

			</DialogContent>
			{product.promotion_tag==="zero_waste"?<>
				<ConeterItemContainer>
					<AutorenewIcon style={iconStyleGreen} />
				</ConeterItemContainer>
				<SmallCenterText>Produkty 'KUPUJĘ RATUJĘ' są to: ostatnie sztuki, produkty z krótkim czasem ważności, produkty nadwyżkowe. Zamówienie powinny być odebrane tego same dnia w którym zostały zakupione.</SmallCenterText>
			</>:null}
			
			{stock===-1?null:<Stock>
					dostępne {stock} szt.
				</Stock>}
			<DialogFooter>
				{price>0?<ConfirmButton onClick={addToBasket} disabled={!rulesAreFulfilled||product.disabled||!is_open||stock===0}>
					{!is_open?"ZAMKNIĘTE":"DODAJ DO KOSZYKA | " + (price + optionsPrice) + " zł"} 
				</ConfirmButton  >:null}
			</DialogFooter>
		</Dialog>
	</> : <></>
}

export function ProductDialog(props)
{
	if (!props.product) return null;
	return <ProductDialogContainer key={"popup"} {...props}/>
}